import React, { useContext, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/common.scss';
import {
    Route,
    BrowserRouter as Router
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { LoginContext, TOKEN_ITEM_KEY } from '../contexts/LoginContext';
import { login } from '../contexts/LoginContext/actions';
import ResetPasswordPage from "./ResetPasswordPage/index";

const App = () => {
    const loginContext = useContext(LoginContext);
    const loadTokenCookie = () => {
        let token = localStorage.getItem(TOKEN_ITEM_KEY);
        if (token) {
            login(token, loginContext.dispatch);
        }
    }

    useEffect(
      () => loadTokenCookie(),
    // eslint-disable-next-line
      []
    );

    return (
        <Router>
            <ToastContainer />
            <Route path="/resetPassword" exact component={ResetPasswordPage} />
        </Router>
    );
}

export default App;
