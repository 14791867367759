import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import treauApiClient, { TreauAPI } from '../../api_clients/TreauApiClient';
import { useToastAPIHandler } from '../../hooks/api';
import Loading from '../../components/Loading';
import { Form, Row, Col, Button } from 'react-bootstrap';
import eyeClosed from '../../images/eye-closed.png';
import eyeOpen from '../../images/eye-open.png';
import logo from '../../images/Logo.png';
import background from '../../images/background.png';
import * as queryString from "querystring";
import './index.scss';

interface PasswordValidation {
  eightChars: boolean;
  oneNumber: boolean;
  oneLowercase: boolean;
  oneUppercase: boolean;
}


const ResetPassword =()=>{

  const history = useHistory();
  const apiHandler = useToastAPIHandler();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [secondPassword, setSecondPassword] = useState<string>("");
  const [hasLeftPasswordInput, setHasLeftPasswordInput] = useState<boolean>(false);
  const [hasResetPassword, setHasResetPassword] = useState<boolean>(false);
  const [isDisplayingPasswords, setIsDisplayingPasswords] = useState<boolean>(false);
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
    eightChars: false,
    oneNumber: false,
    oneLowercase: false,
    oneUppercase: false,
  });
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    const passwordValidation = {
      eightChars: password.length >= 8,
      oneNumber: !!password.match(/[0-9]/),
      oneLowercase: !!password.match(/[a-z]/),
      oneUppercase: !!password.match(/[A-Z]/),
    };
    const isPasswordValid = Object.values(passwordValidation).every(v => v);
    const isFormValid = isPasswordValid && password === secondPassword;

    setPasswordValidation(passwordValidation);
    setIsPasswordValid(isPasswordValid);
    setIsFormValid(isFormValid);
  }, [password, secondPassword]);

  const sendResetRequest = () =>{
    const values = queryString.parse(history.location.search.substring(1));
    if(values.token===undefined){
      toast.error("Invalid Token",{position: toast.POSITION.TOP_CENTER});
      return;
    }
    let data = {
      password: password
    } as TreauAPI.ResetPasswordInput;
    setLoading(true);
    apiHandler.handleRequest(
      treauApiClient.usersAPI.resetPassword(values.token as string, data),
      (response: AxiosResponse<TreauAPI.ResponsesBaseResponse>) => {
        setHasResetPassword(true);
      },
      undefined,
      ()=>setLoading(false)
    );
  }

  const renderShowHideIcon = () => (
    <img
      src={isDisplayingPasswords ? eyeOpen : eyeClosed}
      alt={isDisplayingPasswords ? "hide" : "display"}
      className={`password-reveal ${isDisplayingPasswords ? "open" : "closed"}`}
      onClick={(e) => setIsDisplayingPasswords(!isDisplayingPasswords)}
    />
  );

  const renderForm = () => (
    <div className="reset-form">
      <Form autoComplete="off" autoCapitalize="off" noValidate onSubmit={sendResetRequest}>
        <Row>
          <Form.Group as={Col} xs="12" className="text-center mb-4" controlId="validationPassword">
            <Form.Label>Enter new password</Form.Label>
            <Form.Control
              type={isDisplayingPasswords ? "text" : "password"}
              name="pass"
              onChange={(e) => setPassword(e.target.value)}
              onBlur={(e) => setHasLeftPasswordInput(true)}
              value={password}
              isInvalid={hasLeftPasswordInput && !isPasswordValid}
              className='reset-input'
              autoComplete='new-password'
            />
            {renderShowHideIcon()}
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} xs="12" className="text-center mb-4" controlId="validationPasswordVerify">
            <Form.Label>Re-enter password</Form.Label>
            <Form.Control
              type={isDisplayingPasswords ? "text" : "password"}
              name="confirmPass"
              value={secondPassword}
              onChange={(e) => setSecondPassword(e.target.value)}
              isInvalid={secondPassword.length > 0 && secondPassword !== password}
              className='reset-input'
              autoComplete='new-password'
            />
            {renderShowHideIcon()}
            <Form.Control.Feedback type="invalid">
              {secondPassword.length > 0 && secondPassword !== password && "Passwords do not match"}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <div className="mx-4 d-flex align-items-center">
          <div className={`circle ${passwordValidation.eightChars ? "filled" : "empty"}`}></div>
          <div>At least 8 characters</div>
        </div>
        <div className="mx-4 d-flex align-items-center">
          <div className={`circle ${passwordValidation.oneNumber ? "filled" : "empty"}`}></div>
          <div>At least 1 number</div>
        </div>
        <div className="mx-4 d-flex align-items-center">
          <div className={`circle ${passwordValidation.oneLowercase ? "filled" : "empty"}`}></div>
          <div>At least 1 lowercase letter</div>
        </div>
        <div className="mx-4 d-flex align-items-center">
          <div className={`circle ${passwordValidation.oneUppercase ? "filled" : "empty"}`}></div>
          <div>At least 1 uppercase letter</div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button className='reset-btn' variant="primary" size="lg" disabled={loading || !isFormValid} onClick={sendResetRequest}>Confirm</Button>
        </div>
      </Form>
    </div>
  );

  const renderSuccessMessage = () => (
    <div className="d-flex flex-column align-items-center">
      <p className="text-center success-message">Your password has been successfully changed.</p>
      <p className="text-center success-message">You may now sign into the Gradient app.</p>
    </div>
  );

  return (
    <>
      <Loading isLoading={loading} />
      <div className="reset-password-presentational" style={{ background: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="reset-password-content">
          <div className="logo">
            <img src={logo} alt="" className="" />
          </div>
          <div className="title my-3">{ hasResetPassword ? "Password Changed" : "Reset password" }</div>
          { hasResetPassword ? renderSuccessMessage() : renderForm() }
        </div>
      </div>
    </>
  )
}

export default ResetPassword;
