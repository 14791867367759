import {  LOGIN_ACTION, LOGOUT_ACTION, LoginContextAction} from "."

export const login = (token: string, dispatch: React.Dispatch<LoginContextAction>)=>{
    dispatch({
        type: LOGIN_ACTION,
        token: token,
    })
}

export const logout = (dispatch: React.Dispatch<LoginContextAction>)=>{
    dispatch({
        type: LOGOUT_ACTION,
    })
}