import React, {createContext, useReducer} from 'react';

interface LoginContextState {
    token:string|undefined;
    isLoggedIn: boolean;
}
const initialState:LoginContextState = {
    token: undefined,
    isLoggedIn:false
};

export interface LoginContextAction {
    type: string;
    token?: string;
}

export const TOKEN_ITEM_KEY = "__TREAU_TOKEN__"

type TokenAction = string;
export const LOGIN_ACTION:TokenAction = "LOGIN";
export const LOGOUT_ACTION:TokenAction = "LOGOUT";

const reducer = (state:LoginContextState, action:LoginContextAction) => {
    switch (action.type) {
        case LOGIN_ACTION:
            let newToken = action.token;
            if(newToken){
                if(localStorage) {
                    localStorage.setItem(TOKEN_ITEM_KEY, newToken);
                }
            }
            return {
                token: newToken,
                isLoggedIn: true
            };
        case LOGOUT_ACTION:
            if(localStorage) {
                localStorage.removeItem(TOKEN_ITEM_KEY);
            }
            return {
                token: undefined,
                isLoggedIn: false
            };
        default:
            return state;
    }
};
interface LoginContextType{
    state: LoginContextState,
    dispatch: React.Dispatch<LoginContextAction>
}

export const LoginContext = createContext<LoginContextType>({state:initialState,dispatch: () => null});
export const LoginContextProvider = (props:any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <LoginContext.Provider value={{state, dispatch}}>
            {props.children}
        </LoginContext.Provider>
    );
};

